/* eslint-disable style/no-multi-spaces */
import { format as formatDateFn, formatDistanceToNow, isValid, parse, parseISO } from 'date-fns'
import numeral from 'numeral'

// ---------------------------------------------------------------------------------------------------------------------
// helpers
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Parse date or date-like input to Date object
 *
 * Supports
 *  - timestamp
 *  - Date object
 *  - iso date
 *  - yyyy-mm-dd
 *  - yyyy-mm
 *
 * @param input
 */
export function parseDate(input: string | number | Date | unknown) {
  if (input instanceof Date) {
    return input
  }

  // timestamp
  if (typeof input === 'number') {
    const date = new Date(input)
    return isValid(date) ? date : null
  }

  // string
  if (typeof input === 'string') {
    // full iso
    const isoDate = parseISO(input)
    if (isValid(isoDate)) {
      return isoDate
    }

    // date only
    const formats = ['yyyy-MM', 'yyyy-MM-dd']
    for (const format of formats) {
      const parsedDate = parse(input, format, new Date())
      if (isValid(parsedDate)) {
        return parsedDate
      }
    }
  }

  // otherwise null
  return null
}

/**
 * Parse a numerical input which may include commas or underscores
 *
 * @example
 *
 *    parseNumber('123_456.789') // 123456.789
 *    parseNumber('123,456.789') // 123456.789
 */
function parseNumber(input: string | number) {
  return typeof input !== 'number'
    ? Number(String(input).replace(/[,_]/g, ''))
    : input
}

/**
 * Helper function to parse main, type, args and suffix from ValueFormat
 *
 * Note that the ValueFormat DSL uses commas to split arguments, so if you pass
 * a format with a comma, this function will incorrectly parse it.
 *
 * However, the function also returns a "rest" parameter which can be used in the
 * passing code to manually process (for example, to pass the entire "rest" value
 * to date-fns)
 *
 * @example using presets; use `args` ok
 *
 *    parseFormat('number:short,2')
 *    // { type: 'number', rest: 'short,2', name: 'short', args: [2] }
 *
 * @example using custom value; use `rest` as the comma breaks `args`
 *
 *    parseFormat('number:0,0.00a')
 *    // { type: 'number', rest: '0,0.00a', name: '0', args: ['0.00a'] }
 *
 * @example parsing the suffix
 *
 *    parseFormat('number:short,2 [BTC]')
 *    // { type: 'number', rest: 'short,2', name: 'short', args: [2] }
 */
function parseFormat(format: ValueFormat) {
  const matches = format.match(/(\w+):?(.*)/)
  if (matches) {
    const [_, type, rest] = matches
    if (rest) {
      const [name, ...args] = rest.split(',')
      return {
        type,
        name,
        rest,
        args: args.filter(Boolean).map((arg) => {
          return /^\d/.test(arg)
            ? Number(arg)
            : arg
        }),
      }
    }
    return { type, name: rest || '', args: [] }
  }
  return {}
}

/**
 * Parse value format + optional suffix into parts
 *
 * Given a ValueFormat and optional [SUFFIX], return individual parts as properties:
 *
 *     type   args[] suffix
 *    ┌─┴──┐ ┌──┴──┐  ┌┴┐
 *    number:short,2 [BTC]
 *           └──┬──┘
 *             rest
 */
export function parseValueFormat(fmt: ValueFormat) {
  // Regular expression to capture the different patterns
  const regex = /^(\w+)(?::?(.*?)(?:\[(.+)\])?)?$/

  // Match the input string with the regex
  const match = fmt.match(regex)

  if (!match) {
    return null // Return null if the input doesn't match the pattern
  }

  // Extract captured groups
  const type = match[1] // The type (word characters)
  const format = match[2]?.trim() || '' // The format (anything after colon) or null if not present
  const suffix = match[3]?.trim() || '' // The suffix (inside square brackets) or null if not present

  // Build the 'rest' part, which includes everything from the colon onwards if present, or just the suffix if no colon
  const rest = format
    ? fmt.slice(fmt.indexOf(':') + 1).trim().replace(/\s*\[\w+\]$/, '')
    : ''

  // Split the format by commas, first segment is the name, the rest are args
  let name = ''
  let args = []

  if (format) {
    const formatParts = format.split(',')
    name = formatParts[0] // The first part of the format is the name
    args = formatParts.slice(1).map((arg: string) => {
      const parsed = Number.parseFloat(arg)
      return Number.isNaN(parsed) ? arg.trim() : parsed
    })
  }

  // Return the object
  return {
    type,
    name,
    rest,
    args,
    suffix,
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// types
// ---------------------------------------------------------------------------------------------------------------------

export type Value = undefined | number | string | Date | boolean | any [] | null

type BooleanFormat =
  | 'boolean'           // Yes,No
  | `boolean:${string}` // Yep,Nope, Enabled,Disabled

type PercentFormat =
  | 'percent'           // 12%
  | 'percent:2'         // 12.34%
  | 'percent:3'         // 12.345%

type NumberFormat =
  | 'number'            // 0.0₄12, 1.1234, 1,23.45, 12,345.67, 123.46K, 1.24M
  | 'number:2'          // 1.23, 1,234.56
  | 'number:3'          // 1.234, 1,234.567
  | 'number:short'      // 1M
  | 'number:short,2'    // 1.23M
  | 'number:short,3'    // 1.234M
  | `number:${string}`  // custom

type CurrencyFormat =
  | 'currency'          // $0.0₄12, $1.1234, $1,23.45, $12,345.67, $123.46K, $1.24M
  | 'currency:2'        // $1.23, $1,234.56
  | 'currency:3'        // $1.234, $1,234.567
  | 'currency:short'    // $1M
  | 'currency:short,2'  // $1.23M
  | 'currency:short,3'  // $1.234M

type DateTimeFormat =
  | 'datetime'          // 2024/08/16, 20:00 GMT+1
  | 'datetime:iso'      // 2024-08-16 12:34:56 GMT+1

type DateFormat =
  | 'date'              // 16 Aug 2024
  | 'date:day'          // Aug 16
  | 'date:month'        // Aug '24
  | 'date:iso'          // 2024-08-16
  | `date:${string}`    // custom date-fns

type TimeFormat =
  | 'time'              // 12:34 PM
  | 'time:iso'          // 12:34:56

export type ValueFormat =
  | PercentFormat
  | NumberFormat
  | CurrencyFormat
  | DateTimeFormat
  | DateFormat
  | TimeFormat

// ---------------------------------------------------------------------------------------------------------------------
// formatting functions
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Formatters dictionary
 *
 * @see tests/format-value.test.ts
 */
const formatters = {
  boolean(input: boolean, fmt: BooleanFormat): string {
    const [_, options = 'Yes,No'] = fmt.split(':') // unused for now
    const [truthy, falsy] = options.split(',')
    return input ? truthy : falsy
  },

  percent(input: number | string, fmt: NumberFormat): string {
    const [_, precision] = fmt.split(':')
    const value = Number(input).toFixed(Number(precision || 0))
    return `${value}%`
  },

  number(input: number, fmt: NumberFormat): string {
    // arguments
    const { name, rest, args } = parseFormat(fmt)

    // auto
    if (!name) {
      const num = Math.abs(input)
      if (num < 0.01) {
        return formatters.short(input, 3)
      }
      if (num < 100) {
        return numeral(input).format('0.0000')
      }
      if (num < 100_000) {
        return numeral(input).format('0,0.00')
      }
      return formatters.short(input, 2)
    }

    // short
    if (name === 'short') {
      return formatters.short(input, args[0] || (input < 1 ? 3 : 0))
    }

    // numeric
    const formats: Record<NumberFormat, string> = {
      'number:1': '0,0.0',
      'number:2': '0,0.00',
      'number:3': '0,0.000',
      'number:4': '0,0.0000',
    }
    const format = formats[fmt]
    if (format) {
      return numeral(input).format(format)
    }

    // custom
    const output = numeral(input).format(rest)

    // correct casing of short format
    return /\d\s*[kmbt]$/.test(output)
      ? output.toUpperCase()
      : output
  },

  currency(input: number, inputFmt: NumberFormat): string {
    const fmt = inputFmt.replace('currency', 'number') as NumberFormat
    const value = formatters.number(input, fmt)
    return value.startsWith('-')
      ? `-$${value.slice(1)}` // move minus sign before denomination
      : `$${value}`
  },

  datetime(input: number | string | Date, fmt: DateTimeFormat): string {
    const date = parseDate(input)
    const formats: Record<DateTimeFormat, string> = {
      'datetime': 'yyyy/MM/dd, HH:mm O',
      'datetime:iso': 'yyyy-MM-dd HH:mm:ss O',
    }
    return formatDateFn(date, formats[fmt])
  },

  date(input: number | string | Date, fmt: DateFormat): string {
    const date = parseDate(input)
    const formats: Record<DateFormat, string> = {
      'date': 'MMM dd, yyyy',
      'date:day': 'MMM dd',
      'date:month': 'MMM \'\'yy', // '' is a single '
      'date:iso': 'yyyy-MM-dd',
    }
    const { rest } = parseFormat(fmt)
    const format = formats[fmt] || rest
    if (format === 'since') {
      return formatDistanceToNow(date) // could add unit options `date:since,<unit>`
    }
    return formatDateFn(date, format || formats.date)
  },

  time(input: number | string | Date, fmt: TimeFormat) {
    const date = parseDate(input)
    const formats: Record<TimeFormat, string> = {
      'time': 'hh:mm a',
      'time:iso': 'HH:mm:ss',
    }
    return formatDateFn(date, formats[fmt])
  },

  short(input: number, precision = 2): string {
    const abs = Math.abs(input)
    /**
     * Subscript notation for tiny numbers eg. Shiba Inu price
     */
    if (abs > 0 && abs < 1) {
      const chars = '₀ ₁ ₂ ₃ ₄ ₅ ₆ ₇ ₈ ₉'.split(' ')
      const str = convertToDecimalString(input)
      const matches = str.match(/0.(0+)(\d+)/)

      if (matches) {
        const [, zeroes, digits] = matches
        const ordinals = String(zeroes.length).replace(/\w/g, c => chars[Number(c)])
        return `0.0${ordinals}${digits.slice(0, precision)}`
      }
      return str.slice(0, precision + 2)
    }
    const options = [
      { suffix: 'T', threshold: 1e12 },
      { suffix: 'B', threshold: 1e9 },
      { suffix: 'M', threshold: 1e6 },
      { suffix: 'K', threshold: 1e3 },
      { suffix: ' ', threshold: 1 },
    ]
    const option = options.find(x => abs >= x.threshold)
    return option
      ? (input / option.threshold).toFixed(precision) + option.suffix
      : input.toFixed(precision)
  },
}

// ---------------------------------------------------------------------------------------------------------------------
// exported functions
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Kitchen-sink formatting function
 *
 * Supports complex formatting via simple, single, auto-completing string format:
 *
 * - format:arg1,arg2 [suffix]
 *
 * Functionality:
 *
 * - supports percent, number, currency, date, time and datetime formats
 * - types have optional preset suffixes, i.e. `percent:3' or 'date:month'
 * - number and date types supports custom formatting, i.e. `number:0,0.00000` or `date:Do MMM`
 * - supports suffixes via format `[placeholder], i.e. `number:short,2 [BTC]`
 * - supports multiple date values, i.e. timestamp, ISO string, short-date, or Date object
 * - supports nullish values with English placeholders
 *
 * @usage
 *
 *  formatValue(value, 'currency')
 *  formatValue(value, 'percent:short,3')
 *  formatValue(value, 'number:short,2 [BTC]')
 *  formatValue(value, 'date:Do MMM')
 *  formatValue('no format')
 *  formatValue(null)
 *
 * @see https://github.com/forged-com/forgd/pull/1815
 *
 * @param input     A number or string value (will be parsed)
 * @param fmt       A FormatType preset
 *
 * @see http://numeraljs.com and https://date-fns.org/v4.1.0/docs/format
 */
export function formatValue(input: Value, fmt?: ValueFormat): string {
  // null
  if (input === undefined) {
    return '...'
  }

  // null
  if (input === null) {
    return 'N/A'
  }

  // no format
  if (!fmt) {
    return input
  }

  // parse format
  // TODO return final fmt with function
  const { type, rest, suffix } = parseValueFormat(fmt)
  fmt = rest
    ? `${type}:${rest}`
    : type

  // format
  let output = ''
  switch (type) {
    case 'boolean':
      output = formatters.boolean(input, fmt)
      break

    case 'percent':
      output = formatters.percent(input, fmt)
      break

    case 'number':
      output = formatters.number(parseNumber(input), fmt)
      break

    case 'currency':
      output = formatters.currency(parseNumber(input), fmt)
      break

    case 'date':
      output = formatters.date(input, fmt)
      break

    case 'time':
      output = formatters.time(input, fmt)
      break

    case 'datetime':
      output = formatters.datetime(input, fmt)
      break

    default:
      return input
  }

  // return with optional suffix
  return suffix
    ? `${output} ${suffix}`
    : output
}

/**
 * Helper function to return a curried version of formatValue()
 *
 * @usage
 *
 *  const formatShort = makeFormatter('number:short,3')
 *  const formatted = formatShort(1_000_000)
 */
export function makeFormatter(fmt: ValueFormat) {
  return function(value?: Value) {
    return formatValue(value, fmt)
  }
}

function convertToDecimalString(value: number) {
  const stringValue = value.toString()

  if (stringValue.includes('e') || stringValue.includes('E')) {
    const [base, exponent] = stringValue.split('e')
    const exp = Number.parseInt(exponent)

    const decimalShifted = base.replace('.', '')

    return exp < 0 ? `0.${'0'.repeat(Math.abs(exp) - 1)}${decimalShifted}` : base + '0'.repeat(exp)
  }

  return stringValue
}

// export const formatPercent = formatters.percent
// export const formatNumber = formatters.number
// export const formatCurrency = formatters.currency
// export const formatDate = formatters.date
// export const formatTime = formatters.time
// export const formatDatetime = formatters.datetime
// export const formatShort = formatters.short
